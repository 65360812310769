
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


Vue.config.productionTip = false
import axios from 'axios'
let api = 'http://localhost:5000/'
api = 'http://api.koga-bamok.com/'
if(process.env.NODE_ENV == 'production'){
    api = 'http://api.koga-bamok.com/'
} 

axios.defaults.baseURL = api;
axios.interceptors.response.use(r =>{
    console.log('URL: '+ r.config.url);
    console.log(r);
    return r
})
axios.defaults.headers.post['Content-Type'] = 'application/json';


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
        
        