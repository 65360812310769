
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#02A499',
                purpleCard: '#626ED4',
                success:'#18c59f',
                chipColor:'#cbf3f0',
                danger:'#ef233c',
                warning:'#ff9f1c',
            },
            dark: {
                primary: '#02A499',
                success: '#18c59f',
                chipColor: '#cbf3f0',
                danger: '#ef233c',
                warning: '#ff9f1c',
                purpleCard: '#626ED4',
                messegeReceive:'#3a427e',
                collapse:'#1D2330'
            },
        }
    }
});  
