<template>
	<div class="no-print">
		<v-app-bar app color="primary" dark>
			<v-btn to="/" icon large>
				<v-icon>mdi-home</v-icon>
			</v-btn>
			<v-menu transition="slide-y-transition" offset-y open-on-hover v-for="(item, index) in navigation_links"
				:key="index">
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" class="mx-1" text>
						{{item.title}}
						<v-icon size="15" right>mdi-arrow-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(i, inx) in item.links" :key="inx" @click="$router.push({path: i.path})">
						<v-list-item-title>{{i.title}}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-spacer></v-spacer>
			<v-btn icon class="mx-1" dark @click="$vuetify.theme.dark = !$vuetify.theme.dark">
				<v-icon>mdi-theme-light-dark</v-icon>
			</v-btn>
			<v-menu transition="slide-y-transition" offset-y open-on-hover>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" icon>
						<v-avatar color="primary" size="35">
							<span class="white--text headline">
								{{ (user.user_name || '').charAt(0) }}
							</span>
						</v-avatar>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="logout">
						<v-list-item-title> {{ $store.getters.language.data.logout }} </v-list-item-title>
						<v-list-item-icon>
							<v-icon color="error"> mdi-logout </v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<v-navigation-drawer v-if="$vuetify.breakpoint.mobile" v-model="drawer" app
			:right="$store.getters.language.rtl">
			<template v-slot:prepend>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-center">{{$store.getters.language.app_name}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
			<v-divider class="mt-3"></v-divider>
			<v-list shaped v-for="(item , index) in navigation_links" :key="index">
				<v-subheader>{{item.title}} </v-subheader>
				<v-list-item v-for="(i , inx) in item.links" :key="inx" :to="i.path">
					<v-list-item-content>
						<v-list-item-title>{{i.title}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class="mt-3"></v-divider>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>
	export default {
		data: function () {
			return {
				drawer: null,
				voidLight: require('../assets/void-light.svg'),
			}
		},
		computed: {
			languages() {
				return this.$store.getters.languages
			},
			user() {
				return this.$store.getters.user
			},
			// showStepper() {
			//     return this.$store.getters.accounts_list.length < 1 || 
			//            this.$store.getters.money_move_types_list.length < 1 ||
			//            this.$store.getters.stock_move_types_list.length < 1 ||
			//            this.$store.getters.categories_list.length < 1
			// },
			navigation_links() {
				return this.$store.getters.language.data.navigation_links
			},
		},
		methods: {
			// setLanguage(lang, index) {
			//     this.$store.commit('setLanguage', lang)
			//     this.$vuetify.rtl = lang.rtl
			//     localStorage.setItem('lang', index)
			//     window.location.reload()
			// },
			logout() {
				this.$store.dispatch('logout')
				this.$router.push({
					path: '/login'
				});
			},
			openPage(url) {
				this.$router.push(url)
			}
		},
		mounted() {

		},

	}
</script>
<style>
	.navbar {
		background-color: #626ed4;
	}

	.menu-list-dark {
		background-color: #1d2330;
	}

	.menu-list-light {
		background-color: #e0e0e0;
	}
</style>