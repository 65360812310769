
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index'
import languages from './languages.json'
import { auth } from '../firebase'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        languages: languages,
        language: languages[0],
        store: {},
        user: {},
        auth: {},
        store_initialized: false,
        
        accounts_list: [],
        
        categories_list: [],
        
        customers_list: [],
        
        customer_addresses_list: [],
        
        expenses_list: [],
        
        file_attachments_list: [],
        
        incomes_list: [],
        
        invoices_list: [],
        
        invoice_items_list: [],
        
        items_list: [],
        
        item_images_list: [],
        
        loans_list: [],
        
        money_move_types_list: [],
        
        qist_list: [],
        
        stock_list: [],
        
        stock_move_types_list: [],
        
        stores_list: [],
        
        users_list: [],
        
    },
    getters: {
        language: state => state.language,
        languages: state => state.languages,
        store: state => state.store,
        user: state => state.user,
        auth: state => state.auth,
        
        accounts_list: state => state.accounts_list,
        
        categories_list: state => state.categories_list,
        
        customers_list: state => state.customers_list,
        
        customer_addresses_list: state => state.customer_addresses_list,
        
        expenses_list: state => state.expenses_list,
        
        file_attachments_list: state => state.file_attachments_list,
        
        incomes_list: state => state.incomes_list,
        
        invoices_list: state => state.invoices_list,
        
        invoice_items_list: state => state.invoice_items_list,
        
        items_list: state => state.items_list,
        
        item_images_list: state => state.item_images_list,
        
        loans_list: state => state.loans_list,
        
        money_move_types_list: state => state.money_move_types_list,
        
        qist_list: state => state.qist_list,
        
        stock_list: state => state.stock_list,
        
        stock_move_types_list: state => state.stock_move_types_list,
        
        stores_list: state => state.stores_list,
        
        users_list: state => state.users_list,
        
    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },

        setStore(state, store) {
            state.store = store
        },

        setUser(state, user) {
            state.user = user
        },

        setAuth(state, auth, role) {
            state.auth = auth
            state.auth.role = role;
            localStorage.setItem('void_store_auth', JSON.stringify(auth))
        },
        
        setAccountsList(state, accounts_list) {
            state.accounts_list = accounts_list
        },
        
        setCategoriesList(state, categories_list) {
            state.categories_list = categories_list
        },
        
        setCustomersList(state, customers_list) {
            state.customers_list = customers_list
        },
        
        setCustomerAddressesList(state, customer_addresses_list) {
            state.customer_addresses_list = customer_addresses_list
        },
        
        setExpensesList(state, expenses_list) {
            state.expenses_list = expenses_list
        },
        
        setFileAttachmentsList(state, file_attachments_list) {
            state.file_attachments_list = file_attachments_list
        },
        
        setIncomesList(state, incomes_list) {
            state.incomes_list = incomes_list
        },
        
        setInvoicesList(state, invoices_list) {
            state.invoices_list = invoices_list
        },
        
        setInvoiceItemsList(state, invoice_items_list) {
            state.invoice_items_list = invoice_items_list
        },
        
        setItemsList(state, items_list) {
            state.items_list = items_list
        },
        
        setItemImagesList(state, item_images_list) {
            state.item_images_list = item_images_list
        },
        
        setLoansList(state, loans_list) {
            state.loans_list = loans_list
        },
        
        setMoneyMoveTypesList(state, money_move_types_list) {
            state.money_move_types_list = money_move_types_list
        },
        
        setQistList(state, qist_list) {
            state.qist_list = qist_list
        },
        
        setStockList(state, stock_list) {
            state.stock_list = stock_list
        },
        
        setStockMoveTypesList(state, stock_move_types_list) {
            state.stock_move_types_list = stock_move_types_list
        },
        
        setStoresList(state, stores_list) {
            state.stores_list = stores_list
        },
        
        setUsersList(state, users_list) {
            state.users_list = users_list
        },
        
    },
    actions: {
        init(context, params) {
            axios.get('/init/initStore?uid=' + params.uid).then(r => {
                if(r.data.user) {
                    context.commit('setUser', r.data.user)
                    context.commit('setUsersList', r.data.users)
                    context.commit('setStore', r.data.store)
                    context.commit('setFileAttachmentsList', r.data.file_attachments)
                    context.commit('setCategoriesList', r.data.categories)
                    context.commit('setItemsList', r.data.items)
                    context.commit('setMoneyMoveTypesList', r.data.money_move_types)
                    context.commit('setStockMoveTypesList', r.data.stock_move_types)
                    context.commit('setAccountsList', r.data.accounts)
                    context.commit('setCustomersList', r.data.customers)
                    context.state.store_initialized = true
                }
            })
        },
        login(context, uid) {
            axios.get('/users/filter?column=user_firebase_uid&value=' + uid).then(response => {
                if(response.data?.rows?.length > 0) {
                    context.commit('setAuth', response.data.rows[0], {role: response.data.rows[0].user_role, uid: uid})
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.rows[0].token
                    context.dispatch('init', {uid: uid})
                } else {
                    auth.signOut()
                    localStorage.removeItem('void_store_auth')
                }
            })
        },
        logout() {
            auth.signOut()
            localStorage.removeItem('void_store_auth')
        }
    },
})
        