import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth,signInWithEmailAndPassword , sendPasswordResetEmail } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDI9KbFB5o6AxaFBEoxqKnSpiSFrDjUaAU",
    authDomain: "bamok-store.firebaseapp.com",
    projectId: "bamok-store",
    storageBucket: "bamok-store.appspot.com",
    messagingSenderId: "114605964548",
    appId: "1:114605964548:web:4b51a35f3319444c2b6451",
    measurementId: "G-FH533PY8TY"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth , app , signInWithEmailAndPassword , sendPasswordResetEmail}


