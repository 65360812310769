<template>
    <div>
        <v-container fluid>
            <v-layout row wrap class="d-flex loading-container  align-center justify-center">
                <v-flex xs12 class="d-flex mx-auto align-center justify-center">
                    <div>
                        <v-img class="d-flex mx-auto align-center justify-center" :src="logobw" max-width="300px" alt="" />
                        <br>
                        <br>
                        <v-progress-circular class="d-flex mx-auto align-center" color="primary" :size="30" :value="20" indeterminate></v-progress-circular>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
    export default {
        data: () => ({
            logobw: require('./../assets/void-light.svg'),
            
        }),
        mounted() {
            this.$vuetify.theme.dark = true
            
        },

    };
</script>
<style lang="scss" scoped>
    .loading-container {
        height: 100vh;
    }
    
</style>