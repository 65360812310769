
<template>
    <v-app :class="$store.getters.language.code">
        <div v-if="loading">
            <Loading/>
        </div>
        <div v-else-if="auth">
            <APPNavbar v-if="$route.meta.showNavbar" class="no-print"></APPNavbar>
            <v-main id="v-main" v-if="$store.state.store_initialized">
                <router-view class="ma-0"/>
            </v-main>
        </div>
        <div v-else>
            <v-main>
                <router-view />
            </v-main>
        </div>
    </v-app>
</template>
      
<script>
import {
    getAuth
} from 'firebase/auth'
import APPNavbar from './components/Navbar.vue'
import Loading from './components/Loading.vue'
export default {
    components:{
        APPNavbar,
        Loading
    },
    name: 'App',

    data: () => ({
        auth: false,
        loading: true
    }),
    mounted() {
        this.$vuetify.rtl = this.$store.getters.language.rtl
        const isDark = localStorage.getItem('void_store_dark_theme')
        if(isDark && isDark == 'true') {
            this.$vuetify.theme.dark = true;
        } else {
            this.$vuetify.theme.dark = false;
        }

        getAuth().onAuthStateChanged(user => {
            this.loading = false
            if(user) {
                this.$store.dispatch('login', user.uid)
                this.auth = true
            } else {
                console.log('not logged in');
                getAuth().signOut()
                localStorage.removeItem('void_store_auth')
                this.$router.push('/login')
                this.auth = false
            }
        })

        
    },
    watch: {
        '$vuetify.theme.isDark': function(value) {
            localStorage.setItem('void_store_dark_theme', value);
        }
    }
};
</script>

<style>
    @import url("./assets/stylesheet.css");
    @import url("./assets/style.css");

	.ku > * {
		font-family: "Vazirmatn" !important;
	}

    @media print {
        #v-main {
            padding: 5px !important;
        }
        .only-print {
            display: block !important;
        }
        .no-print {
            display: none !important;
        }
    }
    .only-print {
        display: none;
    }

</style>
