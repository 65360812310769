
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Poss',
        component: () => import('./../views/Invoices/Pos.vue'),
        meta:{
            auth : true,
            title : 'Pos',
            showNavbar: false
        }
    },
    {
        path: '/purchases-list',
        name: 'ListPurchases',
        component: () => import('./../views/Purchases/List.vue'),
        meta: {
            auth: true,
            title: 'Purchases List',
            showNavbar: true
        }
    },
    {
        path: '/purchases-list/:id',
        name: 'ViewPurchases',
        component: () => import('./../views/Purchases/Edit.vue'),
        meta: {
            auth: true,
            title: 'Purchases Edit',
            showNavbar: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta:{
            auth : false,
            title : 'Login',
            showNavbar: false
        }
    },
    
    {
        path: '/accounts-list',
        name: 'ListAccounts',
        component: () => import('./../views/Accounts/List.vue'),
        meta:{
            auth : true,
            title : 'Accounts List',
            showNavbar: true
        }
    },
        {
        path: '/accounts-list/:id',
        name: 'ViewAccounts',
        component: () => import('./../views/Accounts/Edit.vue'),
        meta:{
            auth : true,
            title : 'Accounts Edit',
            showNavbar: true
        }
    },
    {
        path: '/categories-list',
        name: 'ListCategories',
        component: () => import('./../views/Categories/List.vue'),
        meta:{
            auth : true,
            title : 'Categories List',
            showNavbar: true
        }
    },
        {
        path: '/categories-list/:id',
        name: 'ViewCategories',
        component: () => import('./../views/Categories/Edit.vue'),
        meta:{
            auth : true,
            title : 'Categories Edit',
            showNavbar: true
        }
    },
    {
        path: '/customers-list',
        name: 'ListCustomers',
        component: () => import('./../views/Customers/List.vue'),
        meta:{
            auth : true,
            title : 'Customers List',
            showNavbar: true
        }
    },
        {
        path: '/customers-list/:id',
        name: 'ViewCustomers',
        component: () => import('./../views/Customers/Edit.vue'),
        meta:{
            auth : true,
            title : 'Customers Edit',
            showNavbar: true
        }
    },
    {
        path: '/customer_addresses-list',
        name: 'ListCustomer_addresses',
        component: () => import('./../views/Customer_addresses/List.vue'),
        meta:{
            auth : true,
            title : 'Customer_addresses List',
            showNavbar: true
        }
    },
        {
        path: '/customer_addresses-list/:id',
        name: 'ViewCustomer_addresses',
        component: () => import('./../views/Customer_addresses/Edit.vue'),
        meta:{
            auth : true,
            title : 'Customer_addresses Edit',
            showNavbar: true
        }
    },
    {
        path: '/expenses-list',
        name: 'ListExpenses',
        component: () => import('./../views/Expenses/List.vue'),
        meta:{
            auth : true,
            title : 'Expenses List',
            showNavbar: true
        }
    },
        {
        path: '/expenses-list/:id',
        name: 'ViewExpenses',
        component: () => import('./../views/Expenses/Edit.vue'),
        meta:{
            auth : true,
            title : 'Expenses Edit',
            showNavbar: true
        }
    },
    {
        path: '/file_attachments-list',
        name: 'ListFile_attachments',
        component: () => import('./../views/File_attachments/List.vue'),
        meta:{
            auth : true,
            title : 'File_attachments List',
            showNavbar: true
        }
    },
        {
        path: '/file_attachments-list/:id',
        name: 'ViewFile_attachments',
        component: () => import('./../views/File_attachments/Edit.vue'),
        meta:{
            auth : true,
            title : 'File_attachments Edit',
            showNavbar: true
        }
    },
    {
        path: '/incomes-list',
        name: 'ListIncomes',
        component: () => import('./../views/Incomes/List.vue'),
        meta:{
            auth : true,
            title : 'Incomes List',
            showNavbar: true
        }
    },
        {
        path: '/incomes-list/:id',
        name: 'ViewIncomes',
        component: () => import('./../views/Incomes/Edit.vue'),
        meta:{
            auth : true,
            title : 'Incomes Edit',
            showNavbar: true
        }
    },
    {
        path: '/pos/:id?',
        name: 'Pos',
        component: () => import('./../views/Invoices/Pos.vue'),
        meta:{
            auth : true,
            title : 'Pos',
            showNavbar: false
        }
    },
    {
        path: '/invoices-list',
        name: 'ListInvoices',
        component: () => import('./../views/Invoices/List.vue'),
        meta:{
            auth : true,
            title : 'Invoices List',
            showNavbar: true
        }
    },
    {
        path: '/invoices-list/:id',
        name: 'ViewInvoices',
        component: () => import('./../views/Invoices/Edit.vue'),
        meta:{
            auth : true,
            title : 'Invoices Edit',
            showNavbar: true
        }
    },
    {
        path: '/invoice_items-list',
        name: 'ListInvoice_items',
        component: () => import('./../views/Invoice_items/List.vue'),
        meta:{
            auth : true,
            title : 'Invoice_items List',
            showNavbar: true
        }
    },
    {
        path: '/invoice_items-list/:id',
        name: 'ViewInvoice_items',
        component: () => import('./../views/Invoice_items/Edit.vue'),
        meta:{
            auth : true,
            title : 'Invoice_items Edit',
            showNavbar: true
        }
    },
    {
        path: '/items-list',
        name: 'ListItems',
        component: () => import('./../views/Items/List.vue'),
        meta:{
            auth : true,
            title : 'Items List',
            showNavbar: true
        }
    },
        {
        path: '/items-list/:id',
        name: 'ViewItems',
        component: () => import('./../views/Items/Edit.vue'),
        meta:{
            auth : true,
            title : 'Items Edit',
            showNavbar: true
        }
    },
    {
        path: '/item_images-list',
        name: 'ListItem_images',
        component: () => import('./../views/Item_images/List.vue'),
        meta:{
            auth : true,
            title : 'Item_images List',
            showNavbar: true
        }
    },
        {
        path: '/item_images-list/:id',
        name: 'ViewItem_images',
        component: () => import('./../views/Item_images/Edit.vue'),
        meta:{
            auth : true,
            title : 'Item_images Edit',
            showNavbar: true
        }
    },
    {
        path: '/loans-list',
        name: 'ListLoans',
        component: () => import('./../views/Loans/List.vue'),
        meta:{
            auth : true,
            title : 'Loans List',
            showNavbar: true
        }
    },
        {
        path: '/loans-list/:id',
        name: 'ViewLoans',
        component: () => import('./../views/Loans/Edit.vue'),
        meta:{
            auth : true,
            title : 'Loans Edit',
            showNavbar: true
        }
    },
    {
        path: '/money_move_types-list',
        name: 'ListMoney_move_types',
        component: () => import('./../views/Money_move_types/List.vue'),
        meta:{
            auth : true,
            title : 'Money_move_types List',
            showNavbar: true
        }
    },
        {
        path: '/money_move_types-list/:id',
        name: 'ViewMoney_move_types',
        component: () => import('./../views/Money_move_types/Edit.vue'),
        meta:{
            auth : true,
            title : 'Money_move_types Edit',
            showNavbar: true
        }
    },
    {
        path: '/qist/:invoice_id',
        name: 'ListQist',
        component: () => import('./../views/Qist/List.vue'),
        meta:{
            auth : true,
            title : 'Qist List',
            showNavbar: true
        }
    },
        {
        path: '/qist-list/:id',
        name: 'ViewQist',
        component: () => import('./../views/Qist/Edit.vue'),
        meta:{
            auth : true,
            title : 'Qist Edit',
            showNavbar: true
        }
    },
    {
        path: '/stock-list',
        name: 'ListStock',
        component: () => import('./../views/Stock/List.vue'),
        meta:{
            auth : true,
            title : 'Stock List',
            showNavbar: true
        }
    },
    {
        path: '/stock-list/add',
        name: 'AddStock',
        component: () => import('./../views/Stock/Add.vue'),
        meta:{
            auth : true,
            title : 'Stock List',
            showNavbar: true
        }
    },
    {
        path: '/stock-list/edit',
        name: 'ViewStock',
        component: () => import('./../views/Stock/Edit.vue'),
        meta:{
            auth : true,
            title : 'Stock Edit',
            showNavbar: true
        }
    },
    {
        path: '/stock-list/edit/:id',
        name: 'EditStock',
        component: () => import('./../views/Stock/EditSingle.vue'),
        meta:{
            auth : true,
            title : 'Stock Edit',
            showNavbar: true
        }
    },
    {
        path: '/stock_move_types-list',
        name: 'ListStock_move_types',
        component: () => import('./../views/Stock_move_types/List.vue'),
        meta:{
            auth : true,
            title : 'Stock_move_types List',
            showNavbar: true
        }
    },
        {
        path: '/stock_move_types-list/:id',
        name: 'ViewStock_move_types',
        component: () => import('./../views/Stock_move_types/Edit.vue'),
        meta:{
            auth : true,
            title : 'Stock_move_types Edit',
            showNavbar: true
        }
    },
    
    {
        path: '/users-list',
        name: 'ListUsers',
        component: () => import('./../views/Users/List.vue'),
        meta:{
            auth : true,
            title : 'Users List',
            showNavbar: true
        }
    },
    {
        path: '/users-list/:id',
        name: 'ViewUsers',
        component: () => import('./../views/Users/Edit.vue'),
        meta:{
            auth : true,
            title : 'Users Edit',
            showNavbar: true
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('./../views/Profile.vue'),
        meta:{
            auth : true,
            title : 'Profile',
            showNavbar: true
        }
    },
    {
        path: '/account_transfers-list/:sender_id',
        name: 'ListAccount_transfers',
        component: () => import('./../views/Account_transfers/List.vue'),
        meta:{
            auth : true,
            title : 'Account_transfers List',
            showNavbar: true
        }
    },
    {
        path: '/account_transfers/:id',
        name: 'ViewAccount_transfers',
        component: () => import('./../views/Account_transfers/Edit.vue'),
        meta:{
            auth : true,
            title : 'Account_transfers Edit',
            showNavbar: true
        }
    },
    {
        path: '/reports/:id',
        name: 'HomeReport',
        component: () => import('./../views/Reports/Reports.vue'),
        meta:{
            auth : true,
            title : 'Home Reports',
            showNavbar: true
        }
    },
    {
        path: '/reports/account_detail/:account_id/:type',
        name: 'AccountDetaolReport',
        component: () => import('./../views/Reports/Pages/AccountDetail.vue'),
        meta:{
            auth : true,
            title : 'Home Reports',
            showNavbar: true
        }
    },
    {
        path: '/prints/print_invoice/mini/:id',
        name: 'PrintInvoiceMini',
        component: () => import('./../views/Prints/InvoiceMini.vue'),
        meta:{
            auth : true,
            title : 'Home Reports',
            showNavbar: false
        }
    },
    {
        path: '/prints/print_invoice/a4/:id',
        name: 'PrintInvoiceA4',
        component: () => import('./../views/Prints/InvoiceA4.vue'),
        meta:{
            auth : true,
            title : 'Home Reports',
            showNavbar: false
        }
    },
    {
        path: '/prints/stock/:stock_move_type_id/:invoice_number',
        name: 'PrintStock',
        component: () => import('./../views/Prints/Stock.vue'),
        meta:{
            auth : true,
            title : 'Stock Reports',
            showNavbar: false
        }
    },
]

const router = new VueRouter({
    routes,
    mode:'history'
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.auth)) {
        const void_store_auth = localStorage.getItem('void_store_auth')
        if(void_store_auth) {
            next();
        } else {
            next('/login');
        }
    } else {
        next();
    }
    // next()
})

export default router
        